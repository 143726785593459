// Import CSS
import '../styles/index.scss';

// Import Boostrap JavaScript
import 'bootstrap';


$(document).ready(function () {
   // Spara fuori il fullpage
   var w_window = jQuery(window).width();
   var h_window = jQuery(window).height();

  $('.site-wrapper').css({
    height: h_window,
    width: w_window
  });
});


